import { React, useEffect, useState } from 'react'
import styles from '../../styles/Portal.module.scss';
import stylesModal from '../../styles/Modal.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUser } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ModalPortal from './modal';
import axios from "axios";

export default function Portal() {

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [isPro, setIsPro] = useState(false);
    const [linkPatient, setLinkPatient] = useState(false);
    const roleUser = { professionnel: "professionnel", patient: "patient" }
    const [bubbleText, setBubbleText] = useState([]);

    const handleClick = (userRole) => {
        if (userRole === roleUser.professionnel) {
            setIsPro(true);
        } else {
            setIsPro(false);
        }
        setOpenModal(true);
    }

    const handleClose = () => {
        if (linkPatient) {
            setTimeout(() => { setLinkPatient(false) }, 500);
        }
        setOpenModal(false);
    };

    const redirectToLogin = () => {
        navigate('/login');
    }

    const redirectToResetPassword = () => {
        navigate('/reset');
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_NUVEE_BASE_URL + '/bubble-text-default')
            .then(response => {
                setBubbleText(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className={styles.globalPortalContainer}>
            <div className={styles.globalAreasContainer}>
                <div className={styles.patientArea}>
                    <p className={styles.titlesAreas}>Espace&nbsp;patient
                        <FontAwesomeIcon className={styles.iconsAreas} icon={faUser} />
                    </p>
                    <div className={styles.ButtonsAreas}>
                        <Button variant="primary" className={styles.button} onClick={redirectToLogin}>
                            Accéder&nbsp;à&nbsp;mon&nbsp;compte
                        </Button>
                        <Button variant="primary" className={styles.whiteButton} onClick={() => handleClick(roleUser.patient)}>
                            Ma&nbsp;première&nbsp;connexion&nbsp;à&nbsp;Nuvee
                        </Button>
                    </div>
                </div>
                <div className={styles.separator}>
                </div>
                <div className={styles.proArea}>
                    <p className={styles.titlesAreas}>Espace&nbsp;professionel
                        <FontAwesomeIcon className={styles.iconsAreas} icon={faPlus} />
                    </p>
                    <div className={styles.ButtonsAreas}>
                        <Button variant="primary" className={styles.button} onClick={redirectToLogin}>
                            Me&nbsp;connecter
                        </Button>
                        <Button variant="primary" className={styles.whiteButton} onClick={() => handleClick(roleUser.professionnel)}>
                            Premier&nbsp;accès&nbsp;à&nbsp;mon&nbsp;espace&nbsp;pro
                        </Button>
                    </div>
                </div>
            </div>
            <ModalPortal
                handleClose={handleClose}
                title={bubbleText.popupInscriptionPro1Titre}
                show={(isPro && openModal)}
                linkPatient={linkPatient}
                setLinkPatient={setLinkPatient}
                footerModal={
                    <>
                        <Button variant="primary" onClick={redirectToResetPassword}>Recevoir mon lien</Button>
                        <a className={stylesModal.linkPro} href="https://nuvee.fr/contactez-nous/" target="_blank" rel="noreferrer">Contactez-nous</a>
                    </>
                }
            >
                {bubbleText &&
                    <p dangerouslySetInnerHTML={{ __html: bubbleText.popupInscriptionPro1Contenu }}></p>
                }
            </ModalPortal>
            <ModalPortal
                handleClose={handleClose}
                title={!linkPatient ? bubbleText.popupInscriptionPatient1Titre : bubbleText.popupInscriptionPatient2Titre}
                show={(!isPro && openModal)}
                linkPatient={linkPatient}
                setLinkPatient={setLinkPatient}
                footerModal={
                    <>
                        {!linkPatient ? <Button variant="primary" onClick={() => setLinkPatient(true)}>Je ne retrouve pas mon lien</Button>
                            :
                            <Button variant="primary" onClick={redirectToResetPassword}>Demander mon lien</Button>
                        }
                    </>
                } >
                {!linkPatient && bubbleText ?
                    <p dangerouslySetInnerHTML={{ __html: bubbleText.popupInscriptionPatient1Contenu }}></p>
                    :
                    <p dangerouslySetInnerHTML={{ __html: bubbleText.popupInscriptionPatient2Contenu }}></p>

                }
            </ModalPortal>
        </div>
    )
}


